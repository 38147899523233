 import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { brief, education } from '../../utils/icons';
import avatar from '../../img/my-photo.jpeg'
import line from '../../img/line.png'
import Particle from '../Particle';
import { SectionLayout } from '../../styles/Layouts';
import Title from '../Title/Title';
import TimelineItem from '../TimelineItem/TimelineItem';

function Timeline() {
  const theme = useTheme();
  return (
    <TimelineStyled theme={theme}>
        <div className="education timeline">
            <TimelineItem icon={education} duration={'2021 - 2023'} title={'MS in Computer Science (Thesis)'} desc={'University of Wisconsin-Milwaukee, USA'} />
            
            <TimelineItem icon={education} duration={'2016 - 2020'} title={'BS in Computer Science & Engineering'} desc={'Anna University, India'} />
            
        </div>
        <div className="jobs timeline">
            <TimelineItem icon={brief} duration={'Mar 2023 - May 2023'} title={'Full-Stack Developer'} desc={'Medical College of Wisconsin, Milwaukee, USA'} />

            <TimelineItem icon={brief} duration={'June 2022 - Dec 2022'} title={'Software Developer Engineer'} desc={'Kyyba Tech, Boston, USA'} />
            
            <TimelineItem icon={brief} duration={'Jan 2020 - Dec 2020'} title={'Software Developer & Data Analyst'} desc={'Kyyba Innovations, India'} />

        </div>
    </TimelineStyled>
  )
}

const TimelineStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    gap: 2rem;
    .timeline{
        background-color: ${(props) => props.theme.colorBg5};
        padding: 2rem;
        border-radius: 10px;
    }

    .jobs-timeline{
        .icon{
            left: -10px;
        }
    }
`;

export default Timeline
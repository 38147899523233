import React, { useEffect, useRef, useState } from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import Title from '../Title/Title';
import { SectionLayout } from '../../styles/Layouts';
import {projects as myProjects} from '../../data/projects'
import ProjectItem from '../ProjectItems/ProjectItem';
import {getUnique} from '../../utils/helpers'
import { gsap } from 'gsap';
import { Element } from 'react-scroll';

function Projects() {
  const theme = useTheme();
  const [projects, setProjects] = useState(myProjects);
  const [tabs, setTabs] = useState([...getUnique(projects, 'category')]);
  const [active, setActive] = useState(0);
  const projContainer = useRef();

  const activeCategory = (index) => {
    setActive(index)
  }

  const filterProjects = (category, index) => {
    const projs = myProjects.filter((proj) => proj.category === category);
    activeCategory(index);
    setProjects(projs);
    gsap.to(projContainer.current, {
        duration: 0.5,
        opacity: 0,
        y: 25,
        ease: 'power4.out',
        onComplete: () => {
            gsap.to(projContainer.current,
            {
                duration: 0.5,
                y: 20,
                opacity: 1,
                ease: 'power4.out',
            })
            setProjects(projs)
        }
      })
  }

  useEffect(() => {
    filterProjects('Full-Stack Applications',0)
  }, [])
  

  return (
    <Element id='projects-section' name='projects-section'>
        <ProjectsStyled theme={theme}>
        <Title name={'Projects'} />
        <div className="tabs">
            {tabs.map((tab, index) => 
                        <button className={`tab ${active === index ? 'active' : ''}`} key={index} onClick={() => (filterProjects(tab, index))}> {tab} </button>
                    )}
        </div>
        <div className="projects-container" ref={projContainer}>
            {projects.map((project, index) => {
                return <ProjectItem key={index} {...project} />
            })}
        </div>
        </ProjectsStyled>
    </Element>
  )
}

const ProjectsStyled = styled(SectionLayout)`
    background: linear-gradient(180deg, #222121,#191919);

    .projects-container{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 2rem;
    }

    .tabs{
        margin: 2rem 0;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        .tab{
            display: inline-block;
            font-family: inherit;
            font-size: inherit;
            font-weight: 600;
            color: ${props => props.theme.colorGrey1};
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            position: relative;
            transition: all 0.3s ease-in-out;
        }
    }

    .active{
        color: ${props => props.theme.colorPrimary} !important;
        &::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 2px;
            background: ${props => props.theme.colorPrimary};
        }
    }
`;

export default Projects
import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { brief } from '../../utils/icons';
import avatar from '../../img/my-photo.jpeg'
import line from '../../img/line.png'
import Particle from '../Particle';

function Progressbar({skill, progress, bg}) {
  const theme = useTheme();
  return (
    <ProgressbarStyled theme={theme}>
        <div className="text">
            <h5>{skill}</h5>
            <p>{progress}</p>
        </div>
        <div className="progress-container">
            <div className="progress" style={{width: progress + '%', background: bg}}></div>
        </div>
    </ProgressbarStyled>
  )
}

const ProgressbarStyled = styled.div`
    .text{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .progress-container{
        height: 0.5rem;
        width: 100%;
        border-radius: 15px;
        background-color: ${(props) => props.theme.colorGrey2};
        .progress{
            height: 100%;
            border-radius: 15px;
        }
    }
`;

export default Progressbar
import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { Link, ScrollLink } from 'react-scroll';

function Navigation({toggle}) {
  const theme = useTheme();
  return (
    <NavigationStyled toggle={toggle} theme={theme}>
        <ul className="nav-items">
            <li  className='nav-item'>
                <Link to="header-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>Home</Link>
            </li>
            <li className='nav-item'>
                <Link  to="about-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>About</Link>
            </li>
            <li>
                <Link to="experience-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>Experience</Link>
            </li>
            <li  className='nav-item'>
                <Link  to="skills-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>Skills</Link>
            </li>
            <li  className='nav-item'>
                <Link to="projects-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>Projects</Link>
            </li>
            <li  className='nav-item'>
                <Link to="publish-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>Publications</Link>
            </li>
            <li  className='nav-item'>
                <Link to="contact-section" 
                    spy={true} 
                    smooth={true} 
                    duration={500}>Contact</Link>
            </li>
                
                
                
                
        </ul>
    </NavigationStyled>
  )
}

const NavigationStyled = styled.nav`
    opacity: 0.8;
    display: flex;
    position: fixed;
    justify-content: center;
    width: 750px;
    color: ${(props) => props.theme.colorBg2};
    transition: all .3s ease-in-out;
    align-items: center;
    left: 50%;
    top: 0;
    background-color: #131313;
    min-height: 7vh;
    transform: translateX(-50%)
               translateY(${(props) => props.toggle? '-100%':0})
               scaleX(${(props) => props.toggle? 0 : 1});
    z-index: 12;
    margin: 0 auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    filter: blur(${(props) => props.toggle? '10px': '0'});
    .nav-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
        li{
            margin: 1rem 1rem;
            a{
                padding: .6rem .3rem;
                position: relative;
                transition: all .4s ease-in-out;
                color: ${(props) => props.theme.colorWhite};
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0%;
                    height: 2px;
                    background-color: ${(props) => props.theme.colorPrimary};
                    transition: all .4s ease-in-out;
                }
                &:hover:after{
                    width: 100%;
                }
            }
        }
    }

`;

export default Navigation
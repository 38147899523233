import { styled } from "styled-components";
import { useTheme } from "./context/themeContext";
import Header from "./Components/Header/Header";
import Navigation from "./Components/Navigation/Navigation";
import { useState } from "react";
import About from "./Components/Sections/About";
import Skills from "./Components/Sections/Skills";
import Experience from "./Components/Sections/Experience";
import Projects from "./Components/Sections/Projects";
import Publications from "./Components/Sections/Publications";
import Contact from "./Components/Sections/Contacts";

function App() {
  const theme = useTheme();
  console.log(theme);
  const [toggleNav, setToggleNav] = useState(true);
  return (
    <AppStyled theme={theme}>
      <Navigation toggle={toggleNav}/>
      <Header toggle={toggleNav} setToggleNav = {setToggleNav} />
      <main>
        <About />
        <Experience />
        <Skills />
        <Projects />
        <Publications />
        <Contact />
      </main>
    </AppStyled>
  );
}

const AppStyled = styled.div`
  min-height: 100vh;
  p, a, li {
    color: ${(props)=> props.theme.colorGrey1};
  }
  background-color: ${props => props.theme.colorBg};
  color: ${props => props.theme.colorWhite};
  position: relative;
`;

export default App;

import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { brief } from '../../utils/icons';
import avatar from '../../img/my-photo.jpeg'
import line from '../../img/line.png'
import Particle from '../Particle';

function Title({name, desc}) {
  const theme = useTheme();
  return (
    <TitleStyled theme={theme}>
        <h2>{name}</h2>
        <p>{desc}</p>
    </TitleStyled>
  )
}

const TitleStyled = styled.div`
    display: flex;
    flex-direction: column;
    h2{
        font-size: clamp(2rem, 8vw, 3.5rem);
        font-weight: 700;
    }

    p{
        margin-bottom: 2rem;
    }
`;

export default Title
import uuid from "react-uuid"
import { eye, git } from "../utils/icons"
import amazon from '../img/projectThumbnails/amazon-logo.png'
import chef from '../img/projectThumbnails/chef1.png'
import spotify from '../img/projectThumbnails/Spotify-logo.png'
import WhatsApp from '../img/projectThumbnails/WhatsApp.png'
import chatbox from '../img/projectThumbnails/chatbox.png'
import univise from '../img/projectThumbnails/univise.png'
import cycle from '../img/cycle.png'
import urlShortener from '../img/projectThumbnails/url_short.jpeg'
import kidney from '../img/projectThumbnails/kidney.jpeg'
import malaria from '../img/projectThumbnails/malaria.jpeg'
import breast_cancer from '../img/projectThumbnails/cancer.webp'

export const projects = [
    {
        id: uuid(),
        category: "Full-Stack Applications",
        title: "Delicious",
        image: chef,
        link1: "https://github.com/sharath-98/delicious",
        link2: "https://delicious-e372a.web.app/",
        icon1: git,
        icon2: eye,
    },
    {
        id: uuid(),
        category: "Full-Stack Applications",
        title: "Amazon Shopping",
        image: amazon,
        link1: "https://github.com/sharath-98/Amazon",
        link2: "https://clone-55587.web.app/",
        icon1: git,
        icon2: eye,
    },
    {
        id: uuid(),
        category: "Full-Stack Applications",
        title: "Spotify",
        image: spotify,
        link1: "https://github.com/sharath-98/spotify",
        icon1: git,
    },
    {
        id: uuid(),
        category: "Full-Stack Applications",
        title: "URL Shortener",
        image: urlShortener,
        link1: "https://github.com/sharath-98/URL_Shortner",
        icon1: git
    },
    {
        id: uuid(),
        category: "Full-Stack Applications",
        title: "WhatsApp",
        image: WhatsApp,
        link1: "https://github.com/sharath-98/whatsapp",
        link2: "https://whatsapp-web-51359.web.app/",
        icon1: git,
        icon2: eye,
    },

    {
        id: uuid(),
        category: "Mobile Applications",
        title: "Bublr 2.0",
        image: cycle,
        link1: "https://github.com/sharath-98/Bublr-2.0",
        link2: "https://play.google.com/store/apps/details?id=com.bublr.buildyourfirstmap",
        icon1: git,
        icon2: eye,
    },

    {
        id: uuid(),
        category: "Mobile Applications",
        title: "ChatBox",
        image: chatbox,
        link1: "https://github.com/sharath-98/whatsapp",
        icon1: git,
    },
    {
        id: uuid(),
        category: "Machine Leaning",
        title: "Univise - University Recommender App",
        image: univise,
        link1: "https://github.com/sharath-98/whatsapp",
        link2: "https://uni-vise.com",
        icon1: git,
        icon2: eye,
    },
    {
        id: uuid(),
        category: "Machine Leaning",
        title: "Kidney Disease Detection",
        image: kidney,
        link1: "https://github.com/sharath-98/Kidney-disease-prediction",
        icon1: git,
    },
    {
        id: uuid(),
        category: "Machine Leaning",
        title: "Malaria detection",
        image: malaria,
        link1: "https://github.com/sharath-98/Detecting-Malaraia",
        icon1: git,
    },
    {
        id: uuid(),
        category: "Machine Leaning",
        title: "Breast Cancer detection",
        image: breast_cancer,
        link1: "https://github.com/sharath-98/Breast-Cancer-Detection",
        icon1: git,
    },
]
import React, { useState } from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import map from '../../img/map.png'
import Title from '../Title/Title';
import { SectionLayout } from '../../styles/Layouts';
import { Element } from 'react-scroll';
import linkedin from '../../img/linkedin.png'
import github from '../../img/github.png'

function Contact() {
  const theme = useTheme();
  const [msg, setMsg] = useState("");

  function getEmail() {
    return 'mailto:sharathchander.p@gmail.com?body=' + encodeURIComponent(msg)
  }

  return (
    <Element id='contact-section' name='contact-section'>
        <ContactStyled theme={theme}>
        <Title name={'Let\'s Connect'} />
        <div className="contact-info">
                <div className="map-section">
                    <img src={map} alt="" />
                </div>
                
                <form action={getEmail()} className="form-section">
                    
                    <div className="input-control">
                        <textarea placeholder="MESSAGE" name="html_message" id='message' cols="30" rows="6"  onChange={(e)=>setMsg(e.target.value)}  value={msg}></textarea>
                    </div>
                    <div className="btn-con">
                        <Button
                            name="Send Message"
                            blob="blob"
                            bg={theme.colorPrimary}
                            color={theme.colorWhite}
                            bFw={'600'}
                            onClick="onClick"
                            bRad={'30px'}
                            bPad={theme.bPad1}
                        />
                    </div>
                </form>
            </div>
            <div className="footer">
                <a href="https://github.com/sharath-98">
                    <img src={github} width={'40px'} alt="" />
                </a>
                <a href="">
                    <img src={linkedin} width={'40px'} alt="" />
                </a>
            </div>
    </ContactStyled>
    </Element>
  )
}

const ContactStyled = styled(SectionLayout)`
    position: relative;
    .footer{
        width: 9%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .contact-info{
        display: flex;
        gap: 2rem;
        margin-top: 3.rem;
        @media screen and (max-width: 973px){
            flex-direction: column;
        }
        .map-section{
            width: 40%;
            position: relative;
            img{
                width: 100%;
                object-fit: cover;
                opacity: 0.1;
            }
        }
        .form-section{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 1.2rem;
            .input-control{
                display: flex;
                gap: 1rem;
                input, textarea{
                    width: 100%;
                    padding: 1rem 1.5rem;
                    border-radius: 30px;
                    font-size: inherit;
                    outline: none;
                    border: none;
                    font-family: inherit;
                    resize: none;
                    color: gray;
                }
            }
        }
    }
`;

export default Contact
import React from 'react'
import { styled } from 'styled-components'
import { useTheme } from '../../context/themeContext';

function Button({name, blob, bRad, icon, color, bg, bFW, bPad}) {
  const theme = useTheme();
  return (
    <ButtonStyled style={{
        backgroundColor: bg,
        color: color,
        borderRadius: bRad,
        padding: bPad,
        fontWeight: bFW
      }} theme={theme}> 
        {name}
        {icon}
        <div className={blob}></div>
    </ButtonStyled>
  )
}

const ButtonStyled = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    overflow: hidden;
    i{
        margin-left: .6rem;

    }
`;

export default Button
import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { brief } from '../../utils/icons';
import avatar from '../../img/my-photo.jpeg'
import line from '../../img/line.png'
import Particle from '../Particle';
import { SectionLayout } from '../../styles/Layouts';
import Title from '../Title/Title';
import Timeline from '../Timeline/Timeline';
import { Element } from 'react-scroll';

function Experience() {
  const theme = useTheme();
  return (
    <Element id='experience-section' name='experience-section'>
      <ExperienceStyled theme={theme}>
        <Title name={'Experience'} />
        <div className="experiences-container">
            <Timeline/>
        </div>
      </ExperienceStyled>
    </Element>
  )
}

const ExperienceStyled = styled(SectionLayout)`
.experiences-container{
        margin-top: 1.5rem;
    }
`;

export default Experience
import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import publications from '../../data/publications'
import Title from '../Title/Title';
import { SectionLayout } from '../../styles/Layouts';
import PublicationItem from '../PublicationItem/PublicationItem';
import { Element } from 'react-scroll';

function Publications() {
  const theme = useTheme();
  return (
    <Element id='publish-section' name='publish-section'>
        <PublicationsStyled theme={theme}>
        <div className="center-title">
            <Title name={'Research & Publications'} />
        </div>
        <div className="container">
            {
                publications.map((publication) => {
                        return  <PublicationItem {...publication} />
                    })
                }
        </div>
    </PublicationsStyled>
    </Element>
  )
}

const PublicationsStyled = styled(SectionLayout)`
    background-color: ${(props) => props.theme.colorBg5};
    padding-bottom: 360px;
    .center-title{
        display: flex;
        align-items: center;
    }
    .container{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        gap: 2rem;
        margin-top: 3.5rem;
    }
`;

export default Publications
import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { brief } from '../../utils/icons';
import frontend from '../../img/frontend.png'
import backend from '../../img/backend.png'
import database from '../../img/database.png'
import code from '../../img/code.png';
import tools from '../../img/tools.png';
import ai from '../../img/ai.png';
import { SectionLayout } from '../../styles/Layouts';
import Title from '../Title/Title';
import SkillItem from '../SkillItems/SkillItems';
import { Element } from 'react-scroll';

function Skills({toggle, setToggleNav}) {
  const theme = useTheme();
  return (
    <Element id='skills-section' name='skills-section'>
      <SkillsStyled theme={theme}>
        <Title name={'Skills'}/>
        <div className="skill-container">
            <SkillItem icon={frontend}
            title={'Frontend'}
            bg={theme.colorPurple}
            shadow={theme.shadowPurple}
            skillList={'HTML5, CSS, Reac, Redux, Angular, Tailwind CSS, Javascript, Typescript, Bootstrap'} />

            <SkillItem icon={backend}
            title={'Backend'}
            bg={theme.colorPrimary}
            shadow={theme.shadowPrimary}
            skillList={'SpringBoot, .NET 6, Django, Node'} />

            <SkillItem icon={database}
            title={'Database'}
            bg={theme.colorGreen}
            shadow={theme.shadowGreen}
            skillList={' Microsoft SQL Server, MySQL, Oracle, PostgreSQL'} />

            <SkillItem icon={code}
            title={'Programming Languages'}
            bg={theme.colorGreenDark}
            shadow={theme.shadowPurple}
            skillList={'Java, Python, C, C++, C#, Javascript, Typescript'} />

            <SkillItem icon={ai}
            title={'AI'}
            bg={theme.colorPurple}
            shadow={theme.shadowPurple}
            skillList={'Machine Learning, Deep Learning, Natural Language Processing'} />

            <SkillItem icon={tools}
            title={'Others'}
            bg={theme.colorPrimary}
            shadow={theme.shadowPrimary}
            skillList={'REST, AWS, JIRA, Firebase, Heroku, BitBucket, Git, Tableau, Lucid Chart'} />

        </div>
    </SkillsStyled>
    </Element>
  )
}

const SkillsStyled = styled(SectionLayout)`
    background: ${(props) => props.theme.colorBgGrad};
    .skill-container{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        margin-top: 3rem;
        gap: 2rem;

    }
`;

export default Skills 
import { createGlobalStyle } from "styled-components";

export const Globalstyle = createGlobalStyle `
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none; 
    }

    body{
        background-color: #1e1e1e;
        font-family: 'Inter', sans-serif;
        overflow-x: hidden;
        font-size: clamp(1rem, 1.5vw, 1.2rem);
    }

    a, input, button{
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        text-decoration: none;
    }

    #tsparticles{
        height: 100vh;
    }
`;